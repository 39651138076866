.print-card {
  width: 321px;
  height: 204px;
  /* display: flex;
  flex-direction: row; */
  border: 1px solid grey;
  margin-top: 2rem;
  margin-bottom: 2rem;
  margin-right: 2rem;
  margin-left: 2rem;
  /* background-color: red; */
}
.print-card .top {
  display: flex;
  flex-direction: row;
  padding: 2rem;
  padding-top: 1.5rem;
  padding-bottom: 0;
}
.print-card .top .title {
  flex: 4;
}
.print-card .top .title-ltr {
  flex: 4;
  font-size: 10px;
}
.print-card .top .title h5 {
  font-family: "CoconNextArabic-Light", sans-serif !important;
}
.print-card .top .title-ltr h5 {
  font-family: "CoconNextArabic-Light", sans-serif !important;
}
.print-card .top .contract-number {
  flex: 1;
  margin-left: -10px;
  color: gray;
}
.print-card .top .contract-number-ltr {
  flex: 2;
  margin-right: -20px;
  color: gray;
}
.print-card .top .contract-number #number {
  font-size: 14px;
  font-family: Arial;
}
.print-card .top .contract-number-ltr #number {
  font-size: 14px;
  font-family: Arial;
}
.print-card .top .contract-number #number-title {
  margin-top: -1.5rem;
  font-size: 12px;
  font-family: "CoconNextArabic-Light", sans-serif !important;
}

.print-card .top .contract-number-ltr #number-title-ltr {
  margin-top: -1rem;
  font-size: 10px;
  font-family: "CoconNextArabic-Light", sans-serif !important;
}
.print-card .bottom {
  display: flex;
  flex-direction: row;
  flex: 1;
}
.print-card .bottom-ltr {
  display: flex;
  flex-direction: row;
  flex: 1;
}
.print-card .bottom-ltr Label {
  font-family: "CoconNextArabic-Light", sans-serif !important;
  font-size: 13px;
}

.print-card .first-col {
  display: flex;
  flex-direction: column;
  flex: 3;
  margin-top: -1rem;
  margin-right: 1rem;
  /* font-size: 18px; */
  /* float: right; */
}
.print-card .first-col #information {
  font-family: "CoconNextArabic-Light", sans-serif !important;
}
.print-card .first-col-ltr #information {
  font-family: "CoconNextArabic-Light", sans-serif !important;
}
.print-card .first-col-ltr {
  display: flex;
  flex-direction: column;
  flex: 3;
  margin-top: -1rem;
  margin-left: 1rem;
  /* font-size: 18px; */
  /* float: right; */
}
.print-card .second-col {
  flex: 2;
  float: left;
  /* background-color: red; */
  margin-top: -1rem;
}
.print-card .second-col .qrCode {
  margin-left: 1rem;
}
.print-card .second-col .qrCode-ltr {
  margin-right: 1rem;
}

.print-card .second-col #QrCode-title {
  font-size: 10px;
  color: gray;
  margin-top: -1.2rem;
  margin-left: 1rem;
  font-family: "CoconNextArabic-Light", sans-serif !important;
}
.print-card .second-col #QrCode-title-ltr {
  font-size: 9px;
  color: gray;
  margin-top: -1.2rem;
  margin-right: 1rem;
  font-family: "CoconNextArabic-Light", sans-serif !important;
}
