.containerCust {
  max-width: 200px;
  background-color: white;
  border: 1px solid rgb(202, 193, 193, 0.5);
  border-radius: 5px;
}

.characters {
  /* height: 10px; */
}

.character {
  margin: 1%;
  width: 10%;
  line-height: 60px;
  font-size: 36px;
  border: 1px solid transparent;
  border-radius: 8px;
  margin-left: 8px;

  /* border: 1px solid rgb(202, 193, 193, 0.5); */
}

.character--inactive {
  background-color: white;
  box-shadow: none;
  /* border: 1px solid rgb(202, 193, 193, 0.5); */
}

.character--selected {
  border: 1px solid white;
}
.customCenter {
  display: block;
  margin-left: auto;
  margin-right: auto;
  /* width: 40%; */
}

@media (max-width: 576px) {
  .login-card {
    margin-top: 0px;
    padding-top: 0px;
    padding-bottom: 90%;
  }
  .Toastify__toast-container {
    width: 200px;
  }
}

@media (max-width: 768px) {
  .login-card {
    margin-top: 0px;
    padding-top: 0px;
    padding-bottom: 90%;
  }
  .Toastify__toast-container {
    width: 300px;
  }
}
@media (max-width: 992px) {
  .login-card {
    margin-top: 0px;
    padding-top: 0px;
    padding-bottom: 90%;
  }
}
